import { ReactElement, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as PWEye } from 'assets/icons/pwEye.svg';
import { ReactComponent as PwEyeShow } from 'assets/icons/pwEyeShow.svg';
import BlueCheckActive from 'assets/icons/checkActive.svg';
import WhiteCheckboxCheck from 'assets/icons/white-checkbox-check.svg';
import ErrorMessageWithIcon from './errorMessageWithIcon';
import Select, {
  GroupBase,
  OptionProps,
  ValueContainerProps,
  components,
} from 'react-select';

interface CheckboxInput {
  displayCheckboxChildrenInput?: boolean;
  checked?: boolean | undefined | void;
  checkboxLabel?: string | ReactElement;
  checkboxInputLabel?: string;
  checkboxInputTestId?: string;
  bottomMargin?: boolean;
  enableVersatileClick?: boolean;
  isAlternativeCheckboxColor?: boolean;
  checkboxLabelSize?: string;
  checkboxLabelClassName?: string;
  onCheckboxChange?: (checked: boolean) => void;
  onCheckboxChildrenInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

interface SelectInputProps {
  fullWidth?: boolean;
  selectValues?: string[];
  selectWithIconsValues?: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  defaultValue?: string;
  onSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  customClasses?: string;
}

export interface SelectWithFlagsValues {
  label: string;
  value: string;
  flag: string | null | undefined;
}

export interface SelectWithCustomIconsValues {
  label: string;
  value: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

interface SelectInputWithFlagsProps {
  defaultValue?: SelectWithFlagsValues;
  currentValue?: SelectWithFlagsValues;
  options: SelectWithFlagsValues[];
  onSelectChange: (e: SelectWithFlagsValues) => void;
}

interface SelectInputWithCustomIconProps {
  defaultValue?: SelectWithCustomIconsValues;
  currentValue?: SelectWithCustomIconsValues;
  options: SelectWithCustomIconsValues[];
  onSelectChange: (e: SelectWithCustomIconsValues) => void;
}

interface RadioInputProps {
  radioInputValue?: string;
  radioInputCheckedValue?: string | boolean | undefined;
  radioInputLabel?: string;
  radioLabelTextWeight?: string;
  radioLabelClassName?: string;
  radioPosition?: string;
  radioButtonImg?: string;
  radioButtonAlt?: string;
  radioError: string | null;
  onRadioClick?: (value: string) => void;
}

interface TextAreaProps {
  textAreaTitle?: string;
  textAreaSubtitle?: string;
  height?: string;
  resize?: string;
  errorClasses?: string;
  name?: string;
  onTextAreaChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  containerHeight?: string;
}

//@TODO we should add a type for InputProps type prop
interface InputProps {
  decorator?: JSX.Element;
  type: string;
  placeholder?: string;
  name?: string;
  defaultValue?: string;
  disableTelWidth?: boolean;
  errorMsg?: string | null;
  testID?: string;
  pattern?: string;
  noMarginBottom?: boolean;
  flexGrow?: number;
  borderRadiusRight?: boolean;
  borderRadiusLeft?: boolean;
  fontItalic?: boolean;
  value?: string;
  rightText?: string;
  paddingRight?: string;
  leftText?: string;
  leftTextBold?: boolean;
  radioInputProps?: RadioInputProps;
  textAreaProps?: TextAreaProps;
  checkboxProps?: CheckboxInput;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectInputProps?: SelectInputProps;
  selectInputWithFlagsProps?: SelectInputWithFlagsProps;
  selectInputWithCustomIconProps?: SelectInputWithCustomIconProps;
  errorStatus?: boolean;
  errorMsgWithIcon?: string | null;
  isDisabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLengthValue?: number;
  decoratorLeft?: boolean;
  customYPadding?: string;
}

const Input: React.FC<InputProps> = ({
  decorator,
  type,
  placeholder,
  name,
  defaultValue,
  disableTelWidth,
  isDisabled = false,
  errorMsg,
  testID,
  pattern,
  noMarginBottom = false,
  flexGrow = 0,
  borderRadiusRight = false,
  borderRadiusLeft = false,
  fontItalic = false,
  value,
  rightText,
  paddingRight,
  leftText,
  leftTextBold,
  radioInputProps,
  textAreaProps,
  checkboxProps,
  errorStatus = false,
  errorMsgWithIcon = null,
  onChange,
  selectInputProps,
  selectInputWithFlagsProps,
  selectInputWithCustomIconProps,
  onKeyDown,
  maxLengthValue,
  decoratorLeft = false,
  customYPadding = 'py-3',
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [checkboxChildrenInputValue, setCheckboxChildrenInputValue] =
    useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const handleCheckboxChildrenInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (checkboxProps && checkboxProps.checked) {
      setCheckboxChildrenInputValue(event.target.value);
    }

    checkboxProps?.onCheckboxChildrenInputChange &&
      checkboxProps?.onCheckboxChildrenInputChange(event);
  };

  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkboxProps && checkboxProps.checked === false) {
      setCheckboxChildrenInputValue('');
    }

    checkboxProps?.onCheckboxChange &&
      checkboxProps?.onCheckboxChange(event.target.checked);
  };

  const handleCheckboxClick = () => {
    checkboxRef.current?.click();
  };

  const handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    radioInputProps?.onRadioClick &&
      radioInputProps.onRadioClick(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    selectInputProps?.onSelectChange && selectInputProps.onSelectChange(event);
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    textAreaProps?.onTextAreaChange && textAreaProps?.onTextAreaChange(event);
  };

  const ValueContainer = (
    props: JSX.IntrinsicAttributes &
      ValueContainerProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    const data =
      props.getValue()[0] !== undefined
        ? (props.getValue()[0] as { flag: string } | '')
        : selectInputWithFlagsProps?.defaultValue;
    const encodedData =
      typeof data === 'object' ? encodeURIComponent(data.flag ?? '') : '';
    return (
      <>
        <components.ValueContainer {...props}>
          <div className="flex items-center">
            <span className="w-8 mr-[5px] border-[1px]">
              <img
                className="h-full"
                src={`data:image/svg+xml;utf8,${encodedData}`}
                alt="Country Flag"
              />
            </span>
            {props.children}
          </div>
        </components.ValueContainer>
      </>
    );
  };

  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    const data = (props.data as { flag: boolean }).flag;
    const encodedData =
      typeof data === 'string' ? encodeURIComponent(data) : '';
    return (
      <>
        <components.Option {...props}>
          <div className="flex">
            <span className="w-8 mr-[5px] border h-full">
              <img
                className="h-full"
                src={`data:image/svg+xml;utf8,${encodedData}`}
                alt="Country Flag"
              />
            </span>
            {props.children}
          </div>
        </components.Option>
      </>
    );
  };

  const CustomIconOption = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    const CustomIcon = (props.data as SelectWithCustomIconsValues).icon;

    return (
      <components.Option {...props}>
        <div className="flex flex-row gap-1 items-center">
          <CustomIcon />
          {props.children}
        </div>
      </components.Option>
    );
  };

  const CustomIconValueContainer = (
    props: JSX.IntrinsicAttributes &
      ValueContainerProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    const data = props.getValue()[0] as SelectWithCustomIconsValues;
    const CustomIcon = data?.icon;
    return (
      <components.ValueContainer {...props}>
        <div className="flex flex-row gap-1 items-center">
          {CustomIcon && <CustomIcon />}
          {props.children}
        </div>{' '}
      </components.ValueContainer>
    );
  };

  if (type === 'checkbox') {
    const getBgToDisplay = () => {
      if (isDisabled) return 'bg-disabled';
      if (checkboxProps?.checked && checkboxProps?.isAlternativeCheckboxColor)
        return 'bg-clc-blue';
      return 'bg-white';
    };
    const getCheckedMarkToDisplay = () => {
      if (isDisabled) return 'none';
      if (checkboxProps?.checked && checkboxProps?.isAlternativeCheckboxColor)
        return `url(${WhiteCheckboxCheck})`;
      if (checkboxProps?.checked) return `url(${BlueCheckActive})`;
    };
    const baseClasses =
      'flex shrink-0 h-7 w-7 text-clc-blue rounded-5 border input-border__gray appearance-none';
    const disabledClassBg = isDisabled ? 'bg-disabled' : '';
    const checkClass = checkboxProps?.checked ? 'bg-center bg-no-repeat' : '';

    return (
      <>
        <div
          onClick={
            checkboxProps?.enableVersatileClick
              ? handleCheckboxClick
              : undefined
          }
          className={`flex cursor-pointer z-10 ${
            checkboxProps?.bottomMargin ? 'mb-2.5' : ''
          }`}
        >
          <input
            ref={checkboxRef}
            data-testid={testID}
            id={name}
            name={name}
            type="checkbox"
            className={twMerge(
              `${baseClasses} ${checkClass} cursor-pointer ${getBgToDisplay()} ${disabledClassBg}`,
            )}
            onChange={handleCheckboxChange}
            style={{
              backgroundImage: getCheckedMarkToDisplay(),
            }}
            disabled={isDisabled}
          />

          {checkboxProps?.checkboxLabel && (
            <label
              htmlFor={name}
              className={`flex test items-center ml-2.5 text-med-gray cursor-pointer ${checkboxProps.checkboxLabelSize} ${checkboxProps?.checkboxLabelClassName}`}
            >
              {checkboxProps?.checkboxLabel}
            </label>
          )}
        </div>

        {checkboxProps?.displayCheckboxChildrenInput && (
          <div className="ml-10 mt-[1.125rem]">
            <p className="text-dark-gray font-semibold">
              {checkboxProps?.checkboxInputLabel}
            </p>
            <input
              data-testid={checkboxProps?.checkboxInputTestId}
              type="text"
              className="p-[15px] text-sm text-med-gray font-semibold border input-border__gray rounded-[5px] focus:outline-none focus:border-blue-400 w-full"
              placeholder={placeholder}
              name={name}
              defaultValue={defaultValue}
              onChange={handleCheckboxChildrenInputChange}
              pattern={pattern}
              value={checkboxProps?.checked ? checkboxChildrenInputValue : ''}
              disabled={isDisabled}
            />
          </div>
        )}
      </>
    );
  }

  if (type === 'radio') {
    return (
      <label
        className={`flex text-med-gray cursor-pointer ${
          radioInputProps?.radioButtonImg || radioInputProps?.radioPosition
            ? radioInputProps.radioPosition
              ? radioInputProps.radioPosition
              : 'items-start'
            : 'items-center'
        } ${noMarginBottom ? '' : 'mb-2.5'} ${
          radioInputProps?.radioLabelTextWeight
            ? radioInputProps.radioLabelTextWeight
            : ''
        } ${radioInputProps?.radioLabelClassName}`}
      >
        <input
          name={name}
          data-testid={testID}
          type="radio"
          value={radioInputProps?.radioInputValue}
          checked={
            radioInputProps?.radioInputCheckedValue ===
            radioInputProps?.radioInputValue
          }
          className={
            radioInputProps?.radioError !== null
              ? 'appearance-none flex cursor-pointer shrink-0 h-7 w-7 border-alert-negative bg-error-yellow rounded-full mr-2.5 border text-sm font-medium'
              : 'flex cursor-pointer shrink-0 h-7 w-7 text-clc-blue rounded-[5px] border bg-white mr-2.5 text-sm font-medium'
          }
          onChange={handleRadioClick}
          disabled={isDisabled}
        />
        {radioInputProps?.radioInputLabel}
        {radioInputProps?.radioButtonImg && (
          <div className="w-40">
            <img
              className="object-cover w-full"
              src={radioInputProps?.radioButtonImg}
              alt={radioInputProps?.radioButtonAlt}
            />
          </div>
        )}
      </label>
    );
  }

  if (type === 'select') {
    return (
      <select
        data-testid={testID}
        name={name}
        className={twMerge(
          `${errorStatus ? 'border-alert-negative bg-error-yellow' : ''} ${
            selectInputProps?.fullWidth ? 'w-full' : ''
          } h-12 p-3 pr-0 text-base border input-border__gray rounded-md placeholder-gray-500 text-gray-700 focus:outline-none focus:border-blue-400 ${
            borderRadiusRight ? 'rounded-r-none' : ''
          }`,
          selectInputProps?.customClasses ?? '',
        )}
        onChange={handleSelectChange}
        value={value || ''}
      >
        {selectInputProps?.defaultValue && (
          <option value="" disabled>
            {selectInputProps.defaultValue}
          </option>
        )}

        {selectInputProps?.selectValues &&
          selectInputProps.selectValues.map((value: string) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
      </select>
    );
  }

  if (type === 'select-with-country-icons') {
    return (
      <div data-testid={testID}>
        <Select
          value={selectInputWithFlagsProps?.currentValue}
          menuShouldScrollIntoView={false}
          defaultValue={selectInputWithFlagsProps?.defaultValue}
          className="dropdown rounded-l-md border border-med-gray border-r-transparent -mr-2"
          classNamePrefix="react-select-with-icon"
          components={{ Option, ValueContainer }}
          options={selectInputWithFlagsProps?.options}
          onChange={(e) =>
            selectInputWithFlagsProps?.onSelectChange(
              e as SelectWithFlagsValues,
            )
          }
        />
      </div>
    );
  }

  if (type === 'select-with-custom-icons') {
    return (
      <div data-testid={testID}>
        <Select
          value={selectInputWithCustomIconProps?.currentValue}
          menuShouldScrollIntoView={false}
          defaultValue={selectInputWithCustomIconProps?.defaultValue}
          className="dropdown"
          classNamePrefix="react-select-with-icon"
          components={{
            Option: CustomIconOption,
            ValueContainer: CustomIconValueContainer,
          }}
          options={selectInputWithCustomIconProps?.options}
          onChange={(e) =>
            selectInputWithCustomIconProps?.onSelectChange(
              e as SelectWithCustomIconsValues,
            )
          }
        />
      </div>
    );
  }

  if (type === 'text-area') {
    return (
      <>
        <div
          className={`w-full ${textAreaProps?.containerHeight ?? ''}`}
          data-testid={testID}
        >
          <div className="mb-2.5">
            <h6 className="text-base">{textAreaProps?.textAreaTitle}</h6>
            <p className="text-base text-med-gray italic ">
              {textAreaProps?.textAreaSubtitle}
            </p>
          </div>
          <textarea
            name={textAreaProps?.name}
            placeholder={placeholder}
            maxLength={maxLengthValue}
            value={value}
            className={twMerge(`
            ${textAreaProps?.resize ? textAreaProps.resize : 'resize-none'} ${
              textAreaProps?.height ?? ''
            } p-[15px] text-sm text-med-gray font-semibold border input-border__gray rounded-[5px] focus:outline-none focus:border-blue-400 w-full ${
              errorStatus ? 'border-alert-negative bg-error-yellow' : ''
            } 
            ${textAreaProps?.errorClasses ? textAreaProps.errorClasses : ''}
            `)}
            onChange={handleTextAreaChange}
          />
        </div>
        {errorMsg && (
          <p data-testid="pw-error-msg" className="text-sm text-clc-red mt-2">
            {errorMsg}
          </p>
        )}
        {errorMsgWithIcon && (
          <ErrorMessageWithIcon message={errorMsgWithIcon} />
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`relative ${
          noMarginBottom ? '' : 'mb-2.5'
        } flex space-x-2.5 w-full`}
      >
        {leftText && (
          <span
            className={`${
              leftTextBold ? 'font-semibold text-dark-gray' : 'text-med-gray'
            } flex self-center text-sm desktop:ml-2.5 w-24 desktop:w-auto`}
          >
            {leftText}
          </span>
        )}

        <div
          className={`
            flex flex-row gap-2 justify-between bg-white
            ${errorStatus ? 'border-alert-negative bg-error-yellow' : ''} 
            ${flexGrow >= 1 ? 'grow' : 'grow-0'} ${
            borderRadiusLeft ? 'rounded-l-none border-l-silver-gray' : ''
          } ${fontItalic ? 'italic' : ''}
          text-base border input-border__gray rounded-md placeholder-gray-500
          text-gray-700 focus-within:outline-none focus-within:border-blue-400
          overflow-hidden ${
            type === 'tel' || type === 'ext'
              ? `${disableTelWidth ? '' : 'desktop:w-5/12'} w-full`
              : 'w-full'
          }
        `}
        >
          {decorator && decoratorLeft && (
            <div className="pl-3 flex items-center">{decorator}</div>
          )}
          <input
            data-testid={testID}
            autoComplete={type === 'password' ? 'on' : undefined}
            type={
              type === 'password'
                ? isPasswordVisible
                  ? 'text'
                  : 'password'
                : type === 'tel' || type === 'ext'
                ? 'tel'
                : type
            }
            className={`
            ${errorStatus ? 'border-alert-negative bg-error-yellow' : ''} 
            ${
              type === 'tel'
                ? 'grow-0 mobile:w-0 mobile:min-w-full desktop:w-auto desktop:min-w-0'
                : 'grow'
            }
            ${fontItalic ? 'italic' : ''} ${customYPadding} ${
              !decoratorLeft && 'pl-3'
            } ${paddingRight ? paddingRight : 'pr-3'} ${
              type === 'password' ? 'pr-10' : ''
            } placeholder-gray-500 text-med-gray-3 font-semibold text-sm outline-none border-0 focus:outline-none 
            `}
            placeholder={placeholder}
            name={name}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            pattern={pattern}
            value={value}
            maxLength={
              maxLengthValue
                ? maxLengthValue
                : type === 'zip' || type === 'tel'
                ? 20
                : -1
            }
            disabled={isDisabled}
            onKeyDown={onKeyDown}
          />
        </div>
        {rightText && (
          <span className="flex self-center text-sm text-med-gray ml-2.5">
            {rightText}
          </span>
        )}
        {type === 'password' && (
          <div
            className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          >
            {isPasswordVisible ? <PwEyeShow /> : <PWEye />}
          </div>
        )}

        {decorator && !decoratorLeft && (
          <div className="absolute inset-y-0 right-[5px] flex items-center">
            {decorator}
          </div>
        )}
      </div>
      {errorMsg && (
        <p data-testid="pw-error-msg" className="text-sm text-clc-red mt-2">
          {errorMsg}
        </p>
      )}
      {errorMsgWithIcon && <ErrorMessageWithIcon message={errorMsgWithIcon} />}
    </>
  );
};

export default Input;
